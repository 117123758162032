import * as React from "react";
import {
  Wrapper,
  ButtonWrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile,
  GifTile,
  LandingSlider,
  CacheCache,
  VirtualMobile,
  ImageArtistWrapper,
  ArtistBioText,
  ArtistBioWrapper,
  ArtistBio,
  ArtistImage
} from "./UnleashedSpray.module.css";
import Button from "./../../../../components/Button";

import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/graffitiDynamics/tile.png";
import InSitu1 from "./../../../../res/crash/in-situ1.png";
import InSitu2 from "./../../../../res/crash/in-situ2.png";
import InSitu3 from "./../../../../res/crash/in-situ3.png";
import InSitu4 from "./../../../../res/crash/crash-1.png";
import InSitu5 from "./../../../../res/crash/crash-2.png";
import InSitu6 from "./../../../../res/crash/crash-3.png";
import CP from "../../../../res/crash/cp.pdf"
import CTILE from "../../../../res/crash/tile.jpg"
import CSLIDE from "../../../../res/crash/slide.jpg"


const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu1, name: "Explosion, 1981", artistes: "© Crash", description: "Spray paint on canvas", photos: "Photo : Ghost Galerie", style2:"Signed and dated in the composition", dimensions: "107 7⁄8 x 57 9/32 in." },
    { src: InSitu2, name: "Computer style Crash, 1983", artistes: "© Crash", description: "Spray paint and felt on canvas", photos: "Photo : Ghost Galerie", style2:"Signed and dated in the composition and on the reverse", dimensions: "33 7⁄8 x 44 1⁄2 in."  },
    { src: InSitu3, name: "Swiish, 1982", artistes: "© Crash", description: "Spray paint on canvas", photos: "Photo : Ghost Galerie",  style2:"Signed, titled and dated “11-29/89” on the reverse", dimensions: "83 7⁄8 x 60 1⁄4 in" },
    // { src: InSitu4, name: "InSitu4", artistes: "© Dondi White, Lady Pink, Daze, Noc 167, Seen", description: "ECHOES OF THE PAST Ghost Galerie, Paris 2023/2024", photos: "Photo : Ghost Galerie" },
    // { src: InSitu5, name: "InSitu5", artistes: "© Bear, Lee Quinones and A-One", description: "ECHOES OF THE PAST Ghost Galerie, Paris 2023/2024", photos: "Photo : Ghost Galerie" },
    // { src: InSitu6, name: "InSitu6", artistes: "© Lee Quinones and Futura", description: "ECHOES OF THE PAST Ghost Galerie, Paris 2023/2024", photos: "Photo : Ghost Galerie" },
  ],
  photos2: [
    { src: InSitu4},
    { src: InSitu5},
    { src: InSitu6},
  ],
};

const CrashExpo = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div>
       <div className={GifTile}>
        <img src={CTILE} style={{backgroundSize:"contain", width: "80vw", paddingTop: "25px"}}></img>
        </div>
        <div className={LandingSlider}>
        <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={CSLIDE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
    </Carousel>
        </div>
      <div className={Wrapper}>
      <br />
      <div className={CacheCache}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "60px", lineHeight: "77px", fontWeight: "bold"}}>ECHOES OF THE PAST</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>TIMELESS CREATIVITY & EXPLORATION <br/>FROM 1980 TO 1990</p>
            <p style={{fontSize: "16px", lineHeight: "21px", margin: "9px 0 0"}}>John CRASH Matos Retrospective</p>
          </div>
          <div style={{display: "flex"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", marginBottom: "30px", fontWeight: "bold"}}>
            March 21st - April 21st 2024
            </p>
            
          </div>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", margin: "0 0 19px", fontWeight: "bold"}}>PARIS</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>62 RUE DU FAUBOURG <br /> SAINT-HONORÉ</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>75008 PARIS FRANCE</p>
          </div>
          </div>
        </div>
        <div style={{display: "flex",width: "50%", paddingBottom: "40px", paddingLeft: "50px"}}>
          {/* <a href="/en/contactez-nous">
            <p style={{textDecoration: "underline", fontWeight: "bold"}}>CATALOGUE SUR DEMANDE</p>
          </a> */}
          <a  href={CP} style={{textDecoration: "none"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>PRESS</p></a>
          <a href="/en/contactez-nous" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>CONTACT</p></a>
          <a href="/fr/visite-virtuelle-crash" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VIRTUAL VISIT</p></a>
        </div>
      </div>
        <div className={VirtualMobile}>
          <a href="/fr/visite-virtuelle-crash" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VIRTUAL VISIT</p></a>
        </div>
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              // style={{height: "450px"}}
              src={CTILE}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>John CRASH Matos</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1998 à Barcelone, Espagne</p> */}
            <p className={ArtistBioText}>
            Born in 1961, John Matos, better known as CRASH, hails from the Bronx. As a pioneer and figurehead of the movement, his bold artistic path has been essential in redefining the boundaries of New York graffiti and post-graffiti...
            We are in 1975. John imposes CRASH on the streets of New York, invading the subway cars and the city with his colorful creations. These mobile surfaces have become ephemeral canvases that he invests like a rebellious teenager, who thinks and affirms that graffiti is not vandalism.
            Alongside his friends, he explored the Wild Style, an artistic distortion of lettering that became the bold signature of New York graffiti in the 1970s-1980s. Then, in 1979, he joined Studio Graffiti, which enabled artists - such as Dondi, Futura and Daze - to turn to studio production. This moment marked the beginning of a new chapter in his career, as he passionately embraced the Post-graffiti movement, a movement that would in some ways redefine the perception of contemporary art.
              </p>
              <br />
              {/* <div className={ButtonWrapper}>
                <Button
                  alt=""
                  onClick={() => {
                    // handleSendButton();
                  }}
                >
                  Buy Catalog
                </Button>
              </div> */}
            {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueAna}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        </div>
        <div style={{backgroundColor: "#ededed", padding: "50px", marginTop: "50px", display: "flex", flexDirection: "column", marginBottom: "50px"}}>
        
        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}}>
        In the insubordinate glow of contemporary art, which transcends borders and invites reflection, CRASH's creativity resonates like a rebellious melody. Every gesture becomes a note, every work of art a living symphony. Like a composer, he transformed - from the 70s onwards - the streets of New York into a visual score, where concrete and steel play the role of supports for a stylistic rebellion carried by a new instrument: spray paint.
        <br />
        The early stages of his career were rooted in the exuberant effervescence of the 70’s, amid the unbridled turmoil of an underground New York bubbling with vitality and creative genius. From this tumultuous universe emerged an urban poetry - a rebellious graphic symphony defying the ordinary - carried by daring minds and avant-garde artistic expression. They transformed this period into a melting pot of innovative ideas and uprisings, where boldness and protest combined to shape a revolutionary cultural landscape. Then came the end of this era and the beginning of the 80’s: the transition from the street to the studio. This transition was guided by a desire to explore new dimensions beyond the constraints of the urban ephemeral. He perfected his technique, refined his style and deepened his reflection. In this artistic epic, this decade reveals itself as an intoxicating symphony, an open score of bubbling energy. Like a visual crescendo, galleries became for CRASH the illuminated showcases of a new world, enigmatic yet captivating. His singular style, a harmonious fusion of lettering and comics, becomes a dancing melody to the rhythm of his imagination and inspirations, similar to a constantly evolving chord of artistic expression. His early canvases emerge as masterpieces, transcending the codes of contemporary art with audacity. His artworks present themselves not only as aesthetic creations, but also as intrepid testimonies of a resolutely provocative artistic expression.
  <br />
From urban maze to gallery’s' picture rails, CRASH's artistic journey gradually takes shape - where the fleeting and the creative intertwine their destinies. In the revealing intimacy of his studio, a celebration blossoms. A metamorphosis takes place. In this secret laboratory, white canvases become grounds for experimentation. Bold explosions of color, masterfully drawn lettering and nods to pop culture draw an orchestrated visual language that captures the timeless essence of creativity. Inviting us to immerse ourselves in the imaginary, his artworks incite us to feel the elusive, to grasp the spontaneity of the gesture. The studio, in this sense, is not just a physical place, but a portal to CRASH's mental universe. Every stroke, every composition is part of a visual adventure in which the artist, painting and drawing the ephemeral in the immutable, crystallizes the effervescent essence of graffiti. His creations reveal reflections of emotion and meaning, transcending the evanescent to testify to the audacity of post-graffiti, offering a visual mediation on the ephemerality of existence and the permanence of art. From the frenetic energy of the early days to the more subtle explorations of maturity, he became a pioneer of post-graffiti artistic contemporaneity. He explores broader dimensions, challenging established aesthetic norms and demonstrating that beauty, in this context, can take on diverse and sometimes unconventional forms.
Throughout these 40 years of creation, CRASH's artistic legacy has endured and evolved, weaving a singular visual chronicle into the timeless fabric of art. An imprint inscribed in the artistic continuum of time. This exhibition depicts this artistic conversation, where spray paint transcends the mere medium to become a vital force. From bold spontaneity to thoughtful nuance, CRASH becomes a visual narrator, weaving its story through an artistic palette rich in echoes of the eras it has traversed.
<br />
       </p>
        <br />
        </div>
    <div className={Wrapper}>
    
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Selection"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
              <div>

              <img
              className={ImageWrapper}
              key={image.name}
              alt={image.name}
              src={image.src}
              />
               <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
              <strong>{image.name}</strong>
              <p>{image.description}</p>
              <i>{image.artistes}</i>
              <i>{image.style2}</i>
              <p>{image.photos}</p>
              </div> 
              
              </div>
             
              ))}
          </Carousel>
          </div>
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos2.map((image) => (
              <div>

              <img
              className={ImageWrapper}
              key={image.name}
              alt={image.name}
              src={image.src}
              />
               <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
              </div> 
              
              </div>
             
              ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
              </div>
  );
};

export default CrashExpo;
